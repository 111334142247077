<template>

<nav> 
    <nav class="navbar fixed-top navbar-light bg-grey border-bottom">
      <div class="container d-flex justify-content-around">
        <router-link to="/" >Home</router-link>
        <router-link to="/About" >About</router-link>
        <router-link to="/Contact" >Contact</router-link>
      </div>
    </nav>
  </nav>

  <div class="content-container" style="padding-top: 0px;" ref="contentContainer">
    <router-view></router-view>
  </div>


    <!-- Footer -->
    <footer class="footer border-top">
    <div class="container">
      <div class="social-links">
        <a href="https://www.linkedin.com/in/jonathan-back-699a26214/" target="_blank">LinkedIn</a>
        <a href="https://github.com/JonBacc" target="_blank">GitHub</a>
        <a href="https://www.instagram.com/joonathanback/" target="_blank">Instagram</a>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  mounted(){
    //console.log("App loaded")
  }
};
</script>

<style>
@import './resources/bootstrap-icons-1.9.1/bootstrap-icons.css';
@import'./resources/css/bootstrap.min.css';


:root {
  /*animation colors*/
  --g1: #1f1e1e;
  --g2: #000000;

  

  /*app color scheme*/
  --cc_main: #1f1e1e; /*Used as background*/
  --cc_second: #db8b45; /*Used mainly for Buttons and other areas that shall pop out*/
  --cc_third: #f7efd4; /*Used mainly for texts*/



  --cc_a: #edcda4; /*Used when hovering over a link*/
  --cc_b: #e4ac74; /*Used when hovering over buttons, the text and border*/

  --cc_Form: #181717; /*Form background to make it pop out slightly*/

}

@keyframes background-pan {
  from {
    background-position: 0% center;
  }
  
  to {
    background-position: -200% center;
  }
}

.navbar {
  animation: background-pan 10s linear infinite;
  background: linear-gradient(
    to right,
    var(--g1),
    var(--g2),
    var(--g1)
  );
  background-size: 200%;
  overflow: hidden;
  box-shadow: 0 -2px 4px rgba(255, 255, 255, 0.3);
}

.bi {
  font-size: 1.5rem;
}

#app {
  font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  /* background color to the whole app */
  background-color: var(--cc_main);
}

/* Set the text color and background for the whole site */
body {
    color: var(--cc_third);
    background-color: var(--cc_main);
}

body::-webkit-scrollbar{
  display: none;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: var(--cc_third);
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: var(--cc_second);
}

/*Link hover*/
a:hover {
  color: var(--cc_a);
}

table {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

/*Custom button color scheme*/
.btn {
  background-color: var(--cc_second);
  color: var(--cc_main);
  transition: all .7s;
}
.btn:hover {
  color: var(--cc_b);
  background-color: var(--cc_main);
  border-color: var(--cc_b);
}

a{
  color: var(--cc_third);
}

.footer .social-links a {
  margin: 0 10px;
  text-decoration: none;
}

.footer .social-links a:hover {
  color: var(--cc_a);
}

.content-container {
  flex: 1; /* This will make the content container grow and take up available space, pushing the footer down */
  padding-top: 75px;
}

/* Ensure the footer is not fixed to the bottom of the viewport, but rather pushed there by content */
.footer {
  background-color: var(--cc_Form);
  color: var(--cc_third);
  text-align: center;
  padding: 20px;
  width: 100%;
}

</style>
